import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useAppContext } from "src/context/AppContext";
import { version } from "../version";

type Props = {
  open: boolean;
  onClose: () => void;
}

export default function CreditsDialog({ open, onClose }: Props) {

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => handleClose()} open={open}>
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <Grid container columns={2} spacing={0.5}>

          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            Version
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right' }}>
            <Typography>{version}</Typography>
          </Grid>

          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            Creator
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right' }}>
            <Typography>Audun</Typography>
          </Grid>

          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            Idea
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right' }}>
            <Typography>Gabriel</Typography>
          </Grid>

          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            Logo & Background
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right' }}>
            <Typography><Link target="_blank" href="https://openai.com/dall-e-2/">OpenAI DALL-E 2</Link></Typography>
          </Grid>

          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            Icons
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right' }}>
            <Stack direction="column">
              <Typography><Link target="_blank" href="https://fonts.google.com/noto/specimen/Noto+Color+Emoji">Google Noto Color Emoji</Link></Typography>
              <Typography><Link target="_blank" href="https://twemoji.twitter.com/">Twitter Twemoji</Link></Typography>
            </Stack>
          </Grid>

          <Grid item xs={1} sx={{ textAlign: 'left' }}>
            Sounds
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right' }}>
            <Typography><Link target="_blank" href="https://freesound.org/">Freesound</Link></Typography>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
