import React, { createContext, useContext } from 'react';
import Context from './Context';

const AppContext = createContext<Context | null>(null);

export const AppContextProvider = AppContext.Provider;

export function useAppContext(): Context {
    const context = useContext(AppContext);
    if (context === null) {
        throw new Error('useAppContext outside of <AppContextProvider>');
    } else {
        return context;
    }
}