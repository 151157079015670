import { TileProps, TileTag } from "@tilescape/common";

export function filterByQueryTags(list: TileProps[], query: string, tags: string[]): TileProps[] {
    let outlist = list;

    // Filter by query
    if (query) {
        const query_lowercase = query.toLowerCase();
        outlist = outlist.filter((b) =>
            b.name.toLowerCase().includes(query_lowercase) ||
            b.description.toLowerCase().includes(query_lowercase));
    }

    // Filer by tags
    if (tags.length > 0) {
        outlist = outlist.filter((b) => {
            return tags.every((tag) => b.tags.includes(TileTag[tag as keyof typeof TileTag]));
        })
    }

    return outlist;
}