import { Close, Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ALL_TILECONTENT, getTileProps, TileTag } from "@tilescape/common";
import React, { useMemo, useState } from "react";
import { filterByQueryTags } from "../utils";
import { tagToIcon } from "src/util/tag";

type Props = {
  open: boolean;
  onClose: () => void;
};

const all_tags = Object.keys(TileTag).filter((tag) => {
  return isNaN(Number(tag));
});

export default function BuildingHelpDialog({ open, onClose }: Props) {
  const { palette } = useTheme();

  // What tab to have open
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleValueChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  let allBuildingProps = ALL_TILECONTENT.BUILDING.map((building) => getTileProps(building));
  // Sort tiles alphabetically
  allBuildingProps.sort((a, b) => a.name.localeCompare(b.name));

  let allNatureProps = ALL_TILECONTENT.NATURE.map((nature) => getTileProps(nature));

  allBuildingProps = useMemo(() => {
    return filterByQueryTags(allBuildingProps, query, selectedTags)
  }, [allBuildingProps, query, selectedTags]);

  allNatureProps = useMemo(() => {
    return filterByQueryTags(allNatureProps, query, selectedTags)
  }, [allNatureProps, query, selectedTags]);

  return (
    <Dialog open={open} onClose={handleClose} keepMounted fullWidth maxWidth="md">
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Help
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <TextField
        sx={{ ml: 2, mr: 2, mb: 1 }}
        placeholder="Search"
        onChange={(event) => setQuery(event.target.value)}
        value={query}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: query ? (
            <InputAdornment position="end">
              <IconButton onClick={() => setQuery("")}>
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />

      <Box sx={{ ml: 2, mr: 2 }}>
        <FormControl fullWidth>
          <Select
            multiple
            endAdornment={
              selectedTags.length > 0 ? (
                <InputAdornment position="start">
                  <IconButton onClick={() => setSelectedTags([])}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            displayEmpty
            value={selectedTags}
            onChange={(event: SelectChangeEvent<typeof selectedTags>) => {
              const {
                target: { value },
              } = event;
              setSelectedTags(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
              );
            }}
            renderValue={(selected) => (
              selected.length === 0 ?
                <Typography sx={{ color: palette.grey[500] }}>Filter by type</Typography> :
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
          >
            {all_tags.map((tag) => (
              <MenuItem
                key={tag}
                value={tag}
              >
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs variant="fullWidth" value={tabIndex} onChange={handleValueChange}>
          <Tab label="Tiles" />
          <Tab label="Natural" />
        </Tabs>
      </Box>

      <Box sx={{ maxHeight: 500, overflowX: "auto", overflowY: "scroll" }}>
        <Grid container columns={1}>
          <TabPanel value={tabIndex} index={0}>
            {allBuildingProps.length > 0 ? (
              allBuildingProps.map((props, index) => (
                <TilePropsItem
                  key={index}
                  name={props.name}
                  image={props.image}
                  description={props.description}
                  tags={props.tags}
                />
              ))
            ) : (
              <NoMatches query={query} tags={selectedTags} type="tile" />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            {allNatureProps.length > 0 ? (
              allNatureProps.map((props, index) => (
                <React.Fragment key={index}>
                  <TilePropsItem
                    name={props.name}
                    image={props.image}
                    description={props.description}
                    tags={props.tags}
                  />
                  <TilePropsItem
                    name={props.namePositive as string}
                    image={props.imagePositive as string}
                    description={`A rare type of ${props.name} giving positive points to adjacent tiles.`}
                    tags={props.tags}
                  />
                  <TilePropsItem
                    name={props.nameNegative as string}
                    image={props.imageNegative as string}
                    description={`A rare type of ${props.name} giving negative points to adjacent tiles.`}
                    tags={props.tags}
                  />
                </React.Fragment>
              ))
            ) : (
              <NoMatches query={query} tags={selectedTags} type="natural tile" />
            )}
          </TabPanel>
        </Grid>
      </Box>
    </Dialog>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

type TileItemProps = {
  name: string;
  image: string;
  description: string;
  tags: TileTag[];
};

function NoMatches({ query, tags, type }: { query: string, tags: string[], type: string }) {
  return (
    <Typography variant="h6" sx={{ p: 2 }}>
      {query && tags.length === 0 ? <Typography>No matching {type} for '{query}'</Typography> : null}
      {!query && tags.length > 0 ? <Typography>No matching {type} for {tags.length > 1 ? 'types' : 'type'} {tags.join(', ')}</Typography> : null}
      {query && tags.length > 0 ? <Typography>No matching {type} for '{query}' and {tags.length > 1 ? 'types' : 'type'} {tags.join(', ')}</Typography> : null}
    </Typography>
  );
}

function TilePropsItem({ name, image, description, tags }: TileItemProps) {
  const matches700 = useMediaQuery('(min-width:600px)');
  const size = matches700 ? 75 : 55;
  return (
    <Grid key={name} item xs={1}>
      <Stack direction="row" spacing={1}>
        <Box sx={{ p: 2 }}>
          <img src={image} width={size} height={size} />
        </Box>
        <Box sx={{ pr: 2 }}>
          <Typography sx={{ pt: 1 }} variant="h6">
            {name}
          </Typography>
          <Typography sx={{ pt: 1 }} variant={matches700 ? "body1" : "body2"}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
          <Box sx={{ p: 1, ml: -1.5 }}>
            {tags.map((tag) => {
              return (
                <Chip sx={{ m: 0.25 }} label={<Stack direction="row" spacing={0.5}>
                  <Box sx={{ pt: matches700 ? .5 : .65 }}>
                    {tagToIcon(tag, !matches700)}
                  </Box>
                  <Typography sx={{ pt: .25 }}>{TileTag[tag]}</Typography>
                </Stack>} variant="outlined" />
              )
            })}
          </Box>
        </Box>
      </Stack>
      <Divider sx={{ pt: 0, pb: 1 }} />
    </Grid>
  );
}
