import AreYouSureDialog from "./AreYouSureDialog";

type Props = {
    open: boolean;
    onClose: () => void;
    onYes: () => void;
}

export function LeaveRoomPlayingDialog(props: Props) {
    return (
        <AreYouSureDialog
            title="Are you sure?"
            text="Leaving a game under progress means you'll be replaced by a BOT. Rooms with only BOTs will auto-disband."
            {...props}
        />
    );
}