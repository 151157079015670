import { useAppContext } from "src/context/AppContext";
import RenameRoomDialog from "src/pages/RoomList/subcomponents/RenameRoomDialog";
import {
  ArrowBackIos as BackIcon,
  CasinoOutlined,
  Close,
  Edit,
  Logout,
  People as PeopleIcon,
  PlayArrow as PlayIcon,
  Psychology,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Box, Button, Card, CardContent, CircularProgress, Fab, Grid, Slider, Stack, Typography } from "@mui/material";
import { arrayReplaceAt, Room } from "@tilescape/common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareDialog from "./ShareDialog";
import { TilePickerRow } from "./TilePickerRow";
import TileSelectDialog from "./TileSelectDialog";
import PlayerColorCircle from "./PlayerColorCircle";

type Props = {
  room: Room;
};

export default function RoomLobby({ room }: Props) {
  const navigate = useNavigate();
  const context = useAppContext();

  const isMeHost = context.connectionId === room.hostSocketId;

  const [open, setOpen] = useState<boolean>(false);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [tileSelectIndex, setTileSelectIndex] = useState<number>(-1);

  const [numTiles, setNumTiles] = useState<number>(room.settings.numberOfTiles);
  const [roomWidth, setRoomWidth] = useState<number>(room.settings.width);
  const [roomHeight, setRoomHeight] = useState<number>(room.settings.height);

  useEffect(() => setNumTiles(room.settings.numberOfTiles), [room.settings.numberOfTiles]);
  useEffect(() => setRoomWidth(room.settings.width), [room.settings.width]);
  useEffect(() => setRoomWidth(room.settings.height), [room.settings.height]);

  return (
    <>
      <RenameRoomDialog name={room.name} open={isEditingName} onClose={() => setIsEditingName(false)} />
      <TileSelectDialog
        open={tileSelectIndex > -1}
        onClose={() => setTileSelectIndex(-1)}
        selectedTiles={room.settings.buildings}
        onTileSelect={(tile) => {
          let newBuildings = [...room.settings.buildings];
          newBuildings = arrayReplaceAt(newBuildings, tileSelectIndex, tile);
          setTileSelectIndex(-1);
          context.api.updateRoomSettings({
            ...room.settings,
            buildings: newBuildings
          })
        }}
      />
      <ShareDialog open={open} onClose={() => setOpen(false)} room={room} />
      <Card
        sx={{
          textAlign: "center",
          backgroundColor: "#12121299",
          backdropFilter: "blur(15px)",
          position: "relative",
          mt: 4,
        }}
      >
        <CardContent sx={{ mb: -1 }}>
          <Button
            onClick={() => {
              context.api.leaveRoom();
              navigate("/rooms");
            }}
            sx={{ mt: 2, position: "absolute", top: 8, left: 24 }}
            variant="contained"
          >
            <BackIcon /> Leave
          </Button>

          <Button
            onClick={() => {
              setOpen(true);
            }}
            sx={{ mt: 2, position: "absolute", top: 8, right: 24 }}
            variant="contained"
          >
            <QrCodeScannerIcon /> Invite
          </Button>

          {window.location.protocol === "https:" ? (
            <Button
              variant="contained"
              sx={{ mt: 2, position: "absolute", top: 50, right: 24 }}
              onClick={() => {
                navigator.clipboard.writeText(window.location.toString());
              }}
            >
              <>
                <ContentCopyIcon /> Copy Link
              </>
            </Button>
          ) : null}

          <Box
            sx={{
              mt: 6,
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="h6">Lobby</Typography>

              <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
                <Typography variant="h3" sx={{
                  wordBreak: 'break-word'
                }}>{room.name}</Typography>
                {isMeHost ? (
                  <Button
                    onClick={() => {
                      setIsEditingName(true);
                    }}
                  >
                    <Edit fontSize={"large"} />
                  </Button>
                ) : null}
              </Stack>

              {/* TILES */}
              <Typography sx={{ textAlign: "left" }}>TILES</Typography>

              <Grid
                container
                sx={{
                  flexWrap: "nowrap",
                  textAlign: "left",
                  alignItems: "center",
                }}
              >
                {/* Num tiles to choose from */}
                <Grid item xs="auto">
                  <Typography variant="h3" sx={{ mb: 2, mr: 8, textAlign: "left" }}>
                    {room.settings.numberOfTiles}
                  </Typography>
                </Grid>

                {isMeHost ? (
                  <>
                    <Grid item xs={10} sx={{ pr: 3 }}>
                      <Slider
                        value={numTiles}
                        size="medium"
                        onChange={(_, value) => setNumTiles(value as number)}
                        onChangeCommitted={(_, value) => {
                          context.api.updateRoomSettings({
                            ...room.settings,
                            numberOfTiles: value as number,
                          });
                        }}
                        step={1}
                        marks
                        min={3}
                        max={8}
                        valueLabelDisplay="auto"
                      />
                    </Grid>
                    <Stack direction="row" spacing={1}>
                      {/* Show/Hide Tiles Button */}
                      <Button
                        variant="contained"
                        onClick={() => {
                          context.api.updateRoomSettings({
                            ...room.settings,
                            showTilesInLobby: !room.settings.showTilesInLobby
                          });
                        }}
                      >
                        {room.settings.showTilesInLobby ? <Visibility /> : <VisibilityOff />}
                      </Button>
                      {/* Randomize Button */}
                      <Button
                        variant="contained"
                        onClick={() => {
                          context.api.randomizeRoomTiles();
                        }}
                      >
                        <CasinoOutlined />
                      </Button>
                    </Stack>
                  </>
                ) : null}
              </Grid>

              <TilePickerRow
                hidden={!room.settings.showTilesInLobby}
                pointerCursor={isMeHost}
                tiles={room.settings.buildings}
                onSelectTile={(_, index) => { if (room.settings.showTilesInLobby) setTileSelectIndex(index) }}
              />

              {/* BOARDSIZE */}
              <Typography sx={{ textAlign: "left", pt: 2 }}>BOARDSIZE</Typography>

              {/* nxm-slider */}
              <Grid
                container
                sx={{
                  flexWrap: "nowrap",
                  textAlign: "left",
                  alignItems: "center",
                }}
              >
                <Grid item xs="auto">
                  <Typography variant="h3" sx={{ maxWidth: '75px', mb: 2, mr: 8, textAlign: "left" }}>
                    {room.settings.width}x{room.settings.height}
                  </Typography>
                </Grid>

                {isMeHost ? (
                  <Grid item xs={10} sx={{ pr: 1 }}>
                    <Slider
                      value={roomWidth}
                      size="medium"
                      onChange={(_, value) => setRoomWidth(value as number)}
                      onChangeCommitted={(_, value) => {
                        context.api.updateRoomSettings({
                          ...room.settings,
                          width: value as number,
                          height: value as number,
                        });
                      }}
                      step={1}
                      marks
                      min={3}
                      max={10}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Typography sx={{ textAlign: "left" }}>{room.players.length} PLAYER{room.players.length > 1 ? 'S' : ''}</Typography>
              {room.players.map((player, index) => {
                const isMe = context.connectionId === player.id;
                const isHost = room.hostSocketId === player.id;

                return (
                  <Card key={index} sx={{ mt: 1 }} elevation={3}>
                    <CardContent sx={{ position: "relative", width: "100%" }}>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={8} sm={10}>
                          <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
                            <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
                              {isHost ? (
                                <img alt="crown" src="/icons/crown.webp" height={20} width={20} />
                              ) : player.isBot ? (
                                <Psychology fontSize="small" />
                              ) : (
                                <PeopleIcon fontSize="small" />
                              )}
                              {player.connected ? (
                                <PlayerColorCircle color={player.color} />
                              ) : (
                                <CircularProgress
                                  disableShrink
                                  style={{
                                    width: 20,
                                    height: 20,
                                    color: player.color,
                                  }}
                                />
                              )}
                              <Typography variant="h5" sx={{ wordBreak: 'break-word', textAlign: 'left' }}>{isMe ? player.name + " (You)" : player.name}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={4} sm={2} sx={{ display: "flex", justifyContent: "right" }}>
                          {isMeHost && !isHost && (
                            <Button
                              color="warning"
                              sx={{ mb: -1 }}
                              disabled={isHost}
                              variant="contained"
                              onClick={() => context.api.kickPlayer(player.id)}
                            >
                              <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
                                {player.isBot ? (
                                  <>
                                    <Close />
                                  </>
                                ) : (
                                  <>
                                    <Logout />
                                  </>
                                )}
                              </Stack>
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
          </Box>
          {isMeHost && room.players.length < room.settings.maxPlayers ? (
            <Card sx={{ mt: 1, backgroundColor: "transparent" }} elevation={0}>
              <CardContent sx={{ position: "relative", width: "100%" }}>
                <Box sx={{ mb: -1, display: "flex", justifyContent: "center" }}>
                  <Button variant="contained" onClick={() => context.api.addBotToRoom()}>
                    <Psychology /> Add Bot
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ) : null}
        </CardContent>
      </Card>
      <div style={{ height: '40px' }}>

      </div>
      {isMeHost ? (
        <Fab
          disabled={room.players.length < 2}
          onClick={() => context.api.startRoomPlay()}
          size="large"
          color="primary"
          sx={{
            height: 75,
            width: 75,
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <PlayIcon style={{ fontSize: 50 }} />
        </Fab>
      ) : null}
    </>
  );
}
