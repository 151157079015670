import { World, Tile, TileContext, getTileProps, TileTag, ALL_TILECONTENT, RoomSettings, Player, TileContent } from "../game";

export function getTileAt(context: TileContext, x: number, y: number): Tile | null {
    const index = y * context.world.width + x;
    if (x < 0 || x >= context.world.width) return null;
    if (y < 0 || y >= context.world.height) return null;
    return context.world.tiles[index];
}

export function getTilesWithDistanceTo(
    tile: Tile,
    context: TileContext,
    maxDistance: number,
    minDistance: number = 1,
    orthogonal: boolean = false,
): Tile[] {
    let a: Tile[] = [];

    for (let xOff = -maxDistance; xOff <= maxDistance; xOff++) {
        for (let yOff = -maxDistance; yOff <= maxDistance; yOff++) {
            if (Math.max(Math.abs(xOff), Math.abs(yOff)) < Math.abs(minDistance)) continue;

            //       |
            //       |
            // -----[ ]-----
            //       |
            //       |
            if (orthogonal && (tile.x !== tile.x + xOff && tile.y !== tile.y + yOff))
            {
                continue;
            }

            const t = getTileAt(context, xOff + tile.x, yOff + tile.y);

            if (t !== null) {
                a.push(t);
            }
        }
    }
    return a;
}

export function getAdjacentTilesTo(tile: Tile, context: TileContext): Tile[] {
    return getTilesWithDistanceTo(tile, context, 1, 1, false);
}

export function getOrthogonalTilesTo(tile: Tile, context: TileContext): Tile[] {
    return getTilesWithDistanceTo(tile, context, 1, 1, true);
}

export function getHorseTiles(tile: Tile, context: TileContext): Tile[] {
    let tiles: Tile[] = [];

    for (let offset of [
        { x: -2, y: -1 },
        { x: -1, y: -2 },
        { x: 1, y: -2 },
        { x: 2, y: -1 },
        { x: -2, y: 1 },
        { x: -1, y: 2 },
        { x: 1, y: 2 },
        { x: 2, y: 1 },
    ]) {
        const t = getTileAt(context, tile.x + offset.x, tile.y + offset.y);

        if (t !== null) {
            tiles.push(t);
        }
    }
    return tiles;
}

export function getAnyLine(
    startTile: Tile,
    context: TileContext,
    stepX: number,
    stepY: number,
    fromStep: number = 0,
    toStep = Infinity
): Tile[] {
    let line: Tile[] = [];

    const world = context.world;
    const width = context.world.width;
    const height = context.world.height;

    if (stepX == 0 && stepY == 0) {
        let t = getTileAt(context, startTile.x, startTile.y);
        if (t !== null) {
            line.push(t);
        }
        return line;
    }

    for (let step = Math.max(fromStep, 0); step < toStep; step++) {
        let t = getTileAt(context, startTile.x + stepX * step, startTile.y + stepY * step);
        if (t === null) {
            break;
        }
        line.push(t);
    }

    for (let step = 0; step > fromStep; step--) {
        let t = getTileAt(context, startTile.x + stepX * step, startTile.y + stepY * step);
        if (t === null) {
            break;
        }
        line.unshift(t);
    }

    return line;
}

export function getTileGroup(filter: (w: Tile) => boolean, tile: Tile, context: TileContext): Tile[] {
    // Does the first tile satisfy the filter?
    if (!filter(tile)) return [];

    const group: Tile[] = [];

    let toVisit: Tile[] = [tile];

    while (toVisit.length > 0) {
        const newToVisit: Tile[] = [];

        // For all unvisited tiles, add their adjacent tiles that satisfyes the filter to toVisit
        for (const visit of toVisit) {
            // Add adjacent unvisited tiles of unvisitedTile that should be in the group
            newToVisit.push(
                ...getAdjacentTilesTo(visit, context)
                    // Filter out tiles that does not satisfy the group rule
                    .filter((t) => filter(t))
                    // Filter out already visited
                    .filter((t) => !group.includes(t))
            );
        }

        // Update lists
        group.push(...toVisit);
        toVisit = newToVisit;
    }

    return onlyUnique(group);
}

type TileGroup = Tile[];

export function getAdjacentGroupsOfTiles(
    filter: (w: Tile, s?: Tile) => boolean,
    tile: Tile,
    context: TileContext,
    orthogonal: boolean = false
): TileGroup[] {
    // Find all groups
    let groups: TileGroup[] = [];

    // Find all adjacent tiles to get groups out of
    let allAdjacentTiles = getTilesWithDistanceTo(tile, context, 1, 1, orthogonal).filter((w) => filter(w));

    // For each adjacent tile find all tiles in the group it belongs to
    for (let adjacentTile of allAdjacentTiles) {
        const set = new Set<Tile>();
        let a = [adjacentTile];

        while (a.length > 0) {
            let newA = [];
            for (let forest of a) {
                set.add(forest);
                let adj = getTilesWithDistanceTo(forest, context, 1, 1, orthogonal).filter((w) => filter(w, forest));
                adj = adj.filter((w) => !set.has(w));
                for (let f of adj) newA.push(f);
            }
            a = newA;
        }
        // Remove duplicate groups
        if (groups.filter((group) => group.includes(adjacentTile)).length === 0) {
            groups.push(Array.from(set));
        }
    }

    return groups;
}

export function calcTotalScoreFor(tile: Tile, context: TileContext) {
    const tileProps = getTileProps(tile.content);
    let score = tileProps.calculateScore(tile, context);
    let halves = 0;

    if (tile.player !== null) {
        // Hero protects
        let hasHero = false;

        for (let adjHero of getTilesWithDistanceTo(tile, context, 2)) {
            if (adjHero.content === "Hero") {
                hasHero = true;
                break;
            }
        }

        if (!hasHero && tile.content !== "RobotArm") {
            // Remove points for adj Factory if tile is Person
            if (tileProps.tags.includes(TileTag.Person)) {
                const adjFactory = getAdjacentTilesTo(tile, context).filter((w) => w.content === "Factory");
                score -= adjFactory.length * 2;
            }

            // Remove points for adj Building if tile is RundownHouse
            if (tileCheck(tile, TileTag.Building)) {
                score -= getAdjacentTilesTo(tile, context).filter(w => tileCheck(w, "RundownHouse")).length;
            }

            // Remove points for adj Camp!
            score -= getAdjacentTilesTo(tile, context).filter((w) => w.content === "Camp").length * 2;

            // Remove points for adj police
            if (tileCheck(tile, TileTag.Destructive))
                score -= getAdjacentTilesTo(tile, context).filter(w => w.content === "Police").length * 2;


            // Remove points for Garbage Truck
            const adjGarbage = getAdjacentTilesTo(tile, context).filter((w) => w.content === "Truck");
            if (adjGarbage.length > 0) {
                let minus = 0;

                for (let nature of ALL_TILECONTENT.NATURE) {
                    const groups = getAdjacentGroupsOfTiles((t: Tile) => t.content === nature, tile, context);
                    const numberOfTiles = Math.max(...groups.map((g) => g.length));
                    minus = Math.max(numberOfTiles, minus);
                }

                score -= minus * adjGarbage.length;
            }

            // Remove points for Ninja (must be last)
            if (tileCheck(tile, TileTag.Person) || tileCheck(tile, TileTag.Animal)) {
                const adjNinja = getAdjacentTilesTo(tile, context).filter(w => w.content === 'Ninja');
                halves += adjNinja.length;
            }
        }

        // Get points from factory if Vehicle
        if (tileProps.tags.includes(TileTag.Vehicle)) {
            const adjFactory = getAdjacentTilesTo(tile, context).filter((w) => w.content === "Factory");
            score += adjFactory.length * 2;
        }

        // Additional points for special nature
        const adjNature = getAdjacentTilesTo(tile, context).filter((w) =>
            getTileProps(w.content).tags.includes(TileTag.Nature)
        );
        for (let nature of adjNature) {
            if (nature.additionalPoints !== 0) {
                // Gets points for negative
                const absList = ["Giant", "Zombie"];
                score += absList.includes(tile.content) ? Math.abs(nature.additionalPoints) : nature.additionalPoints;
            }
        }

        // Persontiles get points from bank
        if (tileProps.tags.includes(TileTag.Person)) {
            const adjBank = getAdjacentTilesTo(tile, context).filter((w) => w.content === "Bank");
            score += adjBank.length * 2;
        }
    }

    score += tile.additionalPoints;

    score = Math.floor(score * Math.pow(0.5, halves));

    return score;
}

export function calculateScoreFor(tile: Tile, context: TileContext): number {
    return calcTotalScoreFor(tile, context);
}

export function getScoreForPlayer(settings: RoomSettings, world: World, player: Player): number {
    let score = 0;

    const context: TileContext = {
        world: world,
    };

    for (let index = 0; index < world.tiles.length; index++) {
        const tile = world.tiles[index];
        if (tile.player?.id === player.id) {
            score += calculateScoreFor(world.tiles[index], context);
        }
    }

    return score;
}

export function tileCheck(tile: Tile, check: TileTag | TileContent) {
    if (typeof check === "string") {
        return tile.content === check;
    }
    return getTileProps(tile.content).tags.includes(check);
}

export function onlyUnique(list: any[]) {
    return list.filter((value, index, self) => self.indexOf(value) === index);
}

export function getTilesWhere(
    tile: Tile,
    context: TileContext,
    content: TileTag | TileContent,
    tilesToCheck: (tile, context) => Tile[] = getAdjacentTilesTo
) {
    return tilesToCheck(tile, context).filter((t) => tileCheck(t, content));
}

