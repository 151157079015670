import { useAppContext } from "src/context/AppContext";
import useAudio from "src/hooks/useAudio";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import BuildIcon from "@mui/icons-material/Build";
import { Box, Button, Stack, Typography } from "@mui/material";
import { canPlace, getAdjacentTilesTo, getTileProps, Room, Tile, TileContentBuilding, TileTag } from "@tilescape/common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AreYouSureDialog from "./AreYouSureDialog";
import GameBoard from "./GameBoard";
import ScoreCard from "./ScoreCard";
import { TilePickerRow } from "./TilePickerRow";
import { LeaveRoomPlayingDialog } from "./Dialogs";

type Props = {
  room: Room;
};

export default function RoomPlaying({ room }: Props) {
  const context = useAppContext();
  const navigate = useNavigate();

  const [_, toggle] = useAudio("/audio/pling.wav");

  const [gotoLobbyDialogOpen, setGotoLobbyDialogOpen] = useState<boolean>(false);
  const [leaveRoomDialogOpen, setLeaveRoomDialogOpen] = useState<boolean>(false);
  const currentPlayer = room.players[room.gameState.playerTurnIndex];
  const isMeHost = context.connectionId === room.hostSocketId;
  const isMeTurn = currentPlayer.id === context.connectionId;

  useEffect(() => {
    if (isMeTurn) {
      toggle();
    }
  }, [room.gameState.playerTurnIndex]);

  const players = room.players;

  const [selectedItem, setSelectedItem] = useState<TileContentBuilding>(room.settings.buildings[0]);

  const handleCellClick = (tile: Tile) => {
    if (isMeTurn && canPlace(currentPlayer, selectedItem, tile.x, tile.y, room.gameState.world)) {
      context.api.placeBuilding(selectedItem, tile.x, tile.y);
    }
  };

  return (
    <Box>
      {/* Host Dialog: Make room go back to lobby */}
      {isMeHost ? (
        <AreYouSureDialog
          title="Are you sure?"
          text="The game will be lost in order to adjust settings."
          open={gotoLobbyDialogOpen}
          onClose={() => setGotoLobbyDialogOpen(false)}
          onYes={() => context.api.finishedRoomBackToLobby()}
        />
      ) : null}
      {/* Dialog: Leave the room */}
      <AreYouSureDialog
        title="Are you sure?"
        text="Leaving a game under progress means you'll be replaced by a BOT. Rooms with only BOTs will auto-disband."
        open={leaveRoomDialogOpen}
        onClose={() => setLeaveRoomDialogOpen(false)}
        onYes={() => {
          context.api.leaveRoom();
          navigate("/rooms");
        }}
      />
      <LeaveRoomPlayingDialog
        open={leaveRoomDialogOpen}
        onClose={() => setLeaveRoomDialogOpen(false)}
        onYes={() => {
          context.api.leaveRoom();
          navigate('/rooms');
        }}
      />
      <Box sx={{ mt: 2, mb: 1, display: "flex", justifyContent: "center" }}>
        <GameBoard room={room} onClick={handleCellClick} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            background: "linear-gradient(180deg, rgba(99,99,99,1) 0%, rgba(66,66,66,1) 100%)",
            borderRadius: 2,
            p: 1,
            mb: 2,
            maxWidth: 600,
          }}
        >
          <TilePickerRow
            hidden={false}
            highlightSelected
            pointerCursor
            onSelectTile={(selected) => setSelectedItem(selected)}
            tiles={room.settings.buildings}
          />

          {/* Explanation of selected tile */}
          <Box sx={{ pt: 1, textAlign: "center", color: "white" }}>{getTileProps(selectedItem).description}</Box>

          <Box sx={{ pt: 1, display: "flex", justifyContent: "center" }}>
            <Stack direction="column" sx={{ width: '100%' }}>
              <ScoreCard room={room} players={players} />
              <Typography variant="h5" sx={{ pt: 1, textAlign: "center", color: "white" }}>
                {room.gameState.world.tiles.filter((tile) => tile.content === "Empty").length} tiles left to fill
              </Typography>
              {isMeHost ? (
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => setGotoLobbyDialogOpen(true)}>
                  <BuildIcon /> Goto Lobby
                </Button>
              ) : null}
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={() => setLeaveRoomDialogOpen(true)}
              >
                <BackIcon /> Leave Room
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
