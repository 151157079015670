import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAppContext } from "src/context/AppContext";
import { CirclePicker } from "@hello-pangea/color-picker";
import { CasinoOutlined } from "@mui/icons-material";
import { getRandomPossiblePlayerColor, getRandomUsername, possiblePlayerColors } from "@tilescape/common";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function UserSettingsDialog({ open, onClose }: Props) {
  const context = useAppContext();
  const [text, setText] = useState<string>(context.local.username);
  const [color, setColor] = useState<string>(context.local.color);

  const handleClose = () => {
    onClose();
  };

  const handleRandomize = () => {
    setColor(getRandomPossiblePlayerColor());
    setText(getRandomUsername());
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography sx={{ fontSize: '1.5rem', textAlign: "center" }}>
          Settings
        </Typography>


        <Button onClick={handleRandomize} sx={{ position: "absolute", right: 8, top: 16 }}>
          <CasinoOutlined />
        </Button>

      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          pb: 1,
        }}
      >
        <Typography sx={{ textAlign: "left" }}>Name</Typography>
        <TextField
          inputProps={{
            min: 0,
            maxLength: 20,
            style: { fontSize: 20, textAlign: "center" },
          }}
          placeholder="Username..."
          sx={{ m: 1, mt: 0, width: 260, textAlign: "center" }}
          variant="outlined"
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
        <Typography sx={{ textAlign: "left", pb: 1 }}>Color</Typography>
        <CirclePicker
          colors={possiblePlayerColors}
          color={color}
          onChange={(value) => setColor(value.hex)}
        />
      </Box>
      <Button
        sx={{ m: 1, height: 50 }}
        variant="contained"
        onClick={() => {
          if (text.trim() !== "" && text.trim().length >= 3) {
            context.local.setUsername(text);
            context.local.setColor(color);
            context.api.updateUserSettings({ name: text, color: color });
            handleClose();
          }
        }}
      >
        <Typography>Save</Typography>
      </Button>
    </Dialog>
  );
}
