import {
  getAnyLine,
  getAdjacentTilesTo,
  getTilesWithDistanceTo,
  getAdjacentGroupsOfTiles,
  getTileGroup,
  tileCheck,
  onlyUnique,
  getTilesWhere,
  calculateScoreFor,
  getTileAt,
  getOrthogonalTilesTo,
} from "../util";
import { copyWorld, swapTiles } from "../util/world";
import { Move } from "./Move";
import { Tile } from "./Tile";
import { TileContent, TileContentNature } from "./TileContent";
import { ALL_TILECONTENT } from "./TileContentDef";
import { TileTag } from "./TileTag";
import { World } from "./World";

export type TileContext = {
  world: World;
};

export type TileProps = {
  name: string;
  namePositive?: string;
  nameNegative?: string;
  description: string;
  image: string;
  imagePositive?: string;
  imageNegative?: string;
  backgroundColor: string | null;
  tags: TileTag[];
  // Tags of other tiles that are required for this tile to even work and score points.
  // How it works: Array = AND. TileTag[] = OR.
  // Example: [TileTag.Water, [TileTag.Person, TileTag.Building]]
  // Meaning: Water AND (Person OR Building)
  requires: Array<TileTag[] | TileTag>;
  calculateScore: (tile: Tile, context: TileContext) => number;
  onPlace?: (move: Move, world: World) => World;
  onPlaceAdjacent?: (move: Move, xOffset: number, yOffset: number, world: World) => World;
};

type TilePropsMap = Record<TileContent, TileProps>;

export let tileProps: TilePropsMap = {
  // ⬜
  Empty: {
    name: "Empty",
    description: "Nothing.",
    image: "/game/empty.webp",
    backgroundColor: null,
    tags: [],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // 🌲
  Forest: {
    name: "Forest",
    namePositive: "Fruit Forest",
    nameNegative: "Withered Forest",
    description: "A very small forest.",
    image: "/game/tree.webp",
    imagePositive: "/game/tree-positive.webp",
    imageNegative: "/game/tree-negative.webp",
    backgroundColor: "lightgreen",
    tags: [TileTag.Nature, TileTag.Forest],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // ⛰️
  Mountain: {
    name: "Mountain",
    namePositive: "Golden Mountain",
    nameNegative: "Volcano",
    description: "Tall, mighty mountain.",
    image: "/game/mountain.webp",
    imagePositive: "/game/mountain-positive.webp",
    imageNegative: "/game/mountain-negative.webp",
    backgroundColor: "grey",
    tags: [TileTag.Nature, TileTag.Tall, TileTag.Mountain],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // 🌊
  Water: {
    name: "Water",
    namePositive: "Treasured Water",
    nameNegative: "Sharks!",
    description: "Great for fishing.",
    image: "/game/water.webp",
    imagePositive: "/game/water-positive.webp",
    imageNegative: "/game/water-negative.webp",
    backgroundColor: "cyan",
    tags: [TileTag.Nature, TileTag.Water],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // 🏞️
  NationalPark: {
    name: "National Park",
    namePositive: "National Park",
    nameNegative: "National Park",
    description: "Filled with mountains, forests and water.",
    image: "/game/national-park.png",
    imagePositive: "/game/national-park-positive.png",
    imageNegative: "/game/national-park-negative.png",
    backgroundColor: "lightgreen",
    tags: [TileTag.Nature, TileTag.Tall, TileTag.Mountain, TileTag.Water, TileTag.Forest],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // 🌵
  Desert: {
    name: "Desert",
    namePositive: "Oasis",
    nameNegative: "Scorpions!",
    description: "Deserted and dry.",
    image: "/game/desert.png",
    imagePositive: "/game/desert-positive.png",
    imageNegative: "/game/desert-negative.png",
    backgroundColor: "khaki",
    tags: [TileTag.Nature, TileTag.Desert],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // 🚢
  Ship: {
    name: "Ship",
    description: "+1 points for every Water tile between this tile and the closest Tall tile in all 8 straight horizontal, vertical and diagonal directions.",
    image: "/game/ship.webp",
    backgroundColor: null,
    tags: [TileTag.Vehicle, TileTag.Tall],
    requires: [TileTag.Water],
    calculateScore: (tile: Tile, context: TileContext) => {
      let count: number = 0;
      for (let line of [
        getAnyLine(tile, context, 0, -1, 1),
        getAnyLine(tile, context, 0, 1, 1),
        getAnyLine(tile, context, -1, 0, 1),
        getAnyLine(tile, context, 1, 0, 1),
        getAnyLine(tile, context, 1, 1, 1),
        getAnyLine(tile, context, -1, 1, 1),
        getAnyLine(tile, context, 1, -1, 1),
        getAnyLine(tile, context, -1, -1, 1),
      ]) {
        for (let t of line) {
          if (tileCheck(t, TileTag.Water))
            count++;
          if (tileCheck(t, TileTag.Tall))
            break;
        }
      }
      return count;
    },
  },

  // 🏕️
  Camp: {
    name: "Camp",
    description: "Adjacent tiles acquires -2 points.",
    image: "/game/camp.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Person, TileTag.Destructive],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // ⛪
  Church: {
    name: "Church",
    description: "+1 point per adjacent Person. Tiles that are Destructive can't be placed adjacent to this.",
    image: "/game/church.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Tall, TileTag.Protective],
    requires: [TileTag.Destructive],
    calculateScore: (tile: Tile, context: TileContext) => {
      return getTilesWhere(tile, context, TileTag.Person).length;
    },
  },

  // 🎣
  Fisherman: {
    name: "Fisherman",
    description: "Each adjacent Water tile gives +5 points munus the number of adjacent Fishermen to that water.",
    image: "/game/fisherman.webp",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [TileTag.Water],
    calculateScore: (tile: Tile, context: TileContext) => {
      let score = 0;
      const adjWater = getAdjacentTilesTo(tile, context).filter((w) => tileCheck(w, TileTag.Water));
      for (let water of adjWater) {
        score += 5 - getTilesWhere(water, context, "Fisherman").length;
      }
      return score;
    },
  },

  // 🏥
  Hospital: {
    name: "Hospital",
    description: "+2 points per horizontal and vertical tile between this and the closest Hospital (not itself). Gives 0 points if there are no other Hospitals.",
    image: "/game/hospital.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Tall],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      let hospitals = context.world.tiles.filter((p) => tileCheck(p, "Hospital"));

      let minDist = Infinity;

      for (let hospital of hospitals) {
        if (hospital.x === tile.x && hospital.y === tile.y)
          continue;
        const dist = Math.abs(hospital.x - tile.x) + Math.abs(hospital.y - tile.y);
        minDist = Math.min(minDist, dist);
      }

      if (minDist !== Infinity)
        return 2 * (minDist - 1);
      return 0;
    },
  },

  // 🏠
  House: {
    name: "House",
    description: "+1 point per adjacent Forest. Also, every adjacent House gives +1 point for each of their adjacent Forest tiles.",
    image: "/game/house.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Person],
    requires: [TileTag.Forest],
    calculateScore: (tile: Tile, context: TileContext) => {
      const adj = getAdjacentTilesTo(tile, context);
      let adjHouses = adj.filter((a) => tileCheck(a, "House"));
      adjHouses.push(tile);

      let score = 0;
      for (let house of adjHouses) {
        const adjTrees = getAdjacentTilesTo(house, context).filter((t) => tileCheck(t, TileTag.Forest));
        score += adjTrees.length;
      }

      return score;
    },
  },

  // 🏪
  Market: {
    name: "Market",
    description: "+2 point per adjacent non-nature tile of the most frequent tile-type",
    image: "/game/market.webp",
    backgroundColor: null,
    tags: [TileTag.Building],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      const adj = getAdjacentTilesTo(tile, context).filter((p) => p.content !== "Empty" && !getTileProps(p.content).tags.includes(TileTag.Nature));

      let max = 0;
      for (let t of adj) {
        let count = 0;
        for (let t2 of adj) {
          if (t.content === t2.content) {
            count++;
          }
        }
        max = Math.max(max, count);
      }
      return max * 2;
    },
  },

  // 🚇
  Metro: {
    name: "Metro",
    description: "+1 points per tile between this and the closest Metro, Mountain or edge of the map in all four horizontal and vertical directions.",
    image: "/game/metro.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Vehicle],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      let score = 0;
      for (let line of [
        // All rook directions
        getAnyLine(tile, context, 0, -1, 1),
        getAnyLine(tile, context, 0, 1, 1),
        getAnyLine(tile, context, -1, 0, 1),
        getAnyLine(tile, context, 1, 0, 1),
      ]) {
        for (let t of line) {
          if (tileCheck(t, "Metro") || tileCheck(t, TileTag.Mountain)) {
            break;
          }
          score++;
        }
      }
      return score;
    },
  },

  // ⛲
  Fountain: {
    name: "Fountain",
    description: "+1 point for each Nature tile in a distance of 2, but if there are more that one kind of Nature, the least common type of them gives -1 point instead.",
    image: "/game/fountain.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Water],
    requires: [TileTag.Nature],
    calculateScore: (tile: Tile, context: TileContext) => {
      let natureTiles: TileContent[] = [];
      for (let t of getTilesWithDistanceTo(tile, context, 2)) {
        if (tileCheck(t, TileTag.Nature)) {
          natureTiles.push(t.content);
        }
      }

      let numLeastCommonTile = Infinity;
      for (let t of natureTiles) {
        let count = 0;
        for (let t2 of natureTiles) {
          if (t === t2) {
            count++;
          }
        }
        numLeastCommonTile = Math.min(numLeastCommonTile, count);
      }

      // Check if there are only one type of nature
      if (natureTiles.length === numLeastCommonTile)
        return natureTiles.length;

      return natureTiles.length - numLeastCommonTile * 2;
    },
  },

  // ⛏️
  Miner: {
    name: "Miner",
    description: "Adjacent Mountain tiles gives +1 point for each of its adjacent Mountains, also counting itself",
    image: "/game/dwarf.webp",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [TileTag.Mountain],
    calculateScore: (tile: Tile, context: TileContext) => {
      let score = 0;
      const adjMountain = getAdjacentTilesTo(tile, context).filter((w) => tileCheck(w, TileTag.Mountain));

      score += adjMountain.length;

      for (let mountain of adjMountain) {
        let adj = getAdjacentTilesTo(mountain, context);
        adj = adj.filter((a) => tileCheck(a, TileTag.Mountain));
        score += adj.length;
      }
      return score;
    },
  },

  // 🪓
  Lumberjack: {
    name: "Lumberjack",
    description: "+1 point for each Forest in all adjacent Forest groups",
    image: "/game/lumberjack.webp",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [TileTag.Forest],
    calculateScore: (tile: Tile, context: TileContext) => {
      let score = 0;

      let forestGroups = getAdjacentGroupsOfTiles((t: Tile) => tileCheck(t, TileTag.Forest), tile, context);
      for (let group of forestGroups) {
        score += group.length;
      }

      return score;
    },
  },

  // 🎢
  AmusementPark: {
    name: "Amusement Park",
    description: "+1 point per adjacent non-Tall tile and -1 point per adjacent Tall tile",
    image: "/game/amusement-park.png",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Tall, TileTag.Vehicle],
    requires: [TileTag.Tall],
    calculateScore: (tile: Tile, context: TileContext) => {
      let score = 0;
      let adj = getAdjacentTilesTo(tile, context);
      for (let adjTile of adj) {
        if (tileCheck(adjTile, TileTag.Tall)) {
          score--;
        } else {
          score++;
        }
      }
      return score;
    },
  },

  // 🚚
  Truck: {
    name: "Truck",
    description: "Adjacent tiles acquires -1 point per tile in their own largest adjacent same-Nature group",
    image: "/game/truck.webp",
    backgroundColor: null,
    tags: [TileTag.Vehicle, TileTag.Destructive],
    requires: [TileTag.Nature],
    calculateScore: (__: Tile, _: TileContext) => 0,
  },

  // 🏰
  Castle: {
    name: "Castle",
    description: "+1 point per non-Castle tile in the biggest same colored group of tiles. -1 point per adjacent Castle",
    image: "/game/castle.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Tall],
    requires: [],
    calculateScore: function (tile: Tile, context: TileContext): number {
      const playerGroups = getAdjacentGroupsOfTiles(
        (t: Tile, s?: Tile) => t.player && (!s || (s.player && s.player.id === t.player.id)),
        tile,
        context
      );
      let biggestGroupFound = 0;
      for (const group of playerGroups) {
        const groupNoCastle = group.filter((t) => t.content !== "Castle");
        biggestGroupFound = Math.max(biggestGroupFound, groupNoCastle.length);
      }

      return biggestGroupFound - getTilesWhere(tile, context, "Castle").length;
    },
  },

  // 🧙
  Mage: {
    name: "Mage",
    description: "When placed: Turns all adjacent Nature into +1, or into -1 tiles if the mage is placed adjacent to atleast one Destructive tile.",
    image: "/game/mage.webp",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [TileTag.Nature],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return 0;
    },
    onPlace: (move: Move, world: World) => {
      const newWorld = copyWorld(world);
      const tile = newWorld.tiles[move.index];
      let negative = false;
      let tiles = [];
      // Check for negative buildings
      for (let adj of getAdjacentTilesTo(tile, { world: newWorld })) {
        if (ALL_TILECONTENT.NATURE.includes(adj.content as TileContentNature)) {
          tiles.push(adj);
        } else if (tileCheck(adj, TileTag.Destructive)) {
          negative = true;
        }
      }
      // Transform
      for (let tile of tiles) {
        tile.additionalPoints = negative ? -1 : 1;
      }
      return newWorld;
    },
  },

  // 🏫
  School: {
    name: "School",
    description: "+1 point per adjacent Person tile. Another +2 points if Person is not adjacent to another School.",
    image: "/game/school.webp",
    backgroundColor: null,
    tags: [TileTag.Building],
    requires: [TileTag.Person],
    calculateScore: function (tile: Tile, context: TileContext): number {
      let adjPersonTiles = getTilesWhere(tile, context, TileTag.Person);
      let score = 0;

      for (let adj of adjPersonTiles) {
        score += getTilesWhere(adj, context, "School").length === 1 ? 3 : 1;
      }

      return score;
    },
  },

  // 🦸‍♂️
  Hero: {
    name: "Hero",
    description: "+1 point. Other tiles in a radius of 2 is not affected by the negative effects of Destructive tiles.",
    image: "/game/hero.webp",
    backgroundColor: null,
    tags: [TileTag.Person, TileTag.Protective],
    requires: [TileTag.Destructive],
    calculateScore: (__: Tile, _: TileContext) => 1,
  },

  // 🏗️
  Crane: {
    name: "Crane",
    description: "+2 point per adjacent Building tile.",
    image: "/game/crane.webp",
    backgroundColor: null,
    tags: [TileTag.Tall, TileTag.Vehicle],
    requires: [TileTag.Building],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return 2 * getTilesWhere(tile, context, TileTag.Building).length;
    },
  },

  // 🏭
  Factory: {
    name: "Factory",
    description: "Adjacent Vehicle tiles acquires +2 points, and adjacent Person tiles acquires -2 points.",
    image: "/game/factory.webp",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Destructive, TileTag.Tall],
    requires: [[TileTag.Vehicle, TileTag.Person]],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return 0;
    },
  },

  // ⛽
  GasStation: {
    name: "Gas Station",
    description: "+2 point per adjacent Vehicle tile.",
    image: "/game/gasStation.png",
    backgroundColor: null,
    tags: [TileTag.Building],
    requires: [TileTag.Vehicle],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return 2 * getTilesWhere(tile, context, TileTag.Vehicle).length;
    },
  },

  // 🚁
  Helicopter: {
    name: "Helicopter",
    description: "+2 points per Tall tile placed exactly two tile away. -1 point per adjacent Tall tile.",
    image: "/game/helicopter.png",
    backgroundColor: null,
    tags: [TileTag.Vehicle, TileTag.Flying],
    requires: [TileTag.Tall],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return 2 * getTilesWithDistanceTo(tile, context, 2, 2).filter(t => tileCheck(t, TileTag.Tall)).length - getTilesWhere(tile, context, TileTag.Tall).length;
    },
  },

  // 🚴‍♂️
  Bike: {
    name: "Bike",
    description: "+1 point per tile in largest adjacent tile group that is not Bike nor Nature.",
    image: "/game/bike.png",
    backgroundColor: null,
    tags: [TileTag.Vehicle, TileTag.Person],
    requires: [],
    calculateScore: function (tile: Tile, context: TileContext): number {
      let biggestGroup = 0;
      const adj = getAdjacentTilesTo(tile, context);
      for (let t of adj) {
        if (tileCheck(t, "Bike") || tileCheck(t, TileTag.Nature) || tileCheck(t, "Empty"))
          continue;
        biggestGroup = Math.max(biggestGroup, getTileGroup((w) => w.content === t.content, t, context).length);
      }
      return biggestGroup;
    },
  },

  // ⛳
  GolfCourse: {
    name: "Golf Course",
    description: "+6 points divided by the number of Golf Courses in Golf Course group (rounded down).",
    image: "/game/golf.png",
    backgroundColor: null,
    tags: [TileTag.Nature],
    requires: [],
    calculateScore: function (tile: Tile, context: TileContext): number {
      const group = getTileGroup(
        (w) => {
          return w.content === tile.content;
        },
        tile,
        context
      );
      // The Max is to prevent division by zero (wich would not happen)
      return Math.floor(6 / Math.max(group.length, 1));
    },
  },

  // 🛰️
  Satellite: {
    name: "Satellite",
    description: "+1 point per Building tile placed exactly two tiles away.",
    image: "/game/satellite.png",
    backgroundColor: null,
    tags: [TileTag.Vehicle, TileTag.Flying],
    requires: [TileTag.Building],
    calculateScore: (tile: Tile, context: TileContext) => {
      return getTilesWithDistanceTo(tile, context, 2, 2).filter(t => tileCheck(t, TileTag.Building)).length;
    },
  },

  // 🏦
  Bank: {
    name: "Bank",
    description: "+8 points, but -2 points per adjacent Person tile (to a maximum of -8 points). Adjacent Person tiles acquires +2 point.",
    image: "/game/bank.png",
    backgroundColor: null,
    tags: [TileTag.Building],
    requires: [TileTag.Person],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return Math.max(8 - getTilesWhere(tile, context, TileTag.Person).length * 2, 0);
    },
  },

  // 🐔
  Chicken: {
    name: "Chicken",
    description: "+8 points if this has 2 or more adjacent Chickens. -1 point per adjacent Chicken",
    image: "/game/chicken.png",
    backgroundColor: null,
    tags: [TileTag.Animal],
    requires: [],
    calculateScore: function (tile: Tile, context: TileContext): number {
      let adjScore = getTilesWhere(tile, context, "Chicken").length >= 2 ? 8 : 0;
      const groups = getAdjacentGroupsOfTiles((w) => w.content === 'Chicken', tile, context);
      const minus = getTilesWhere(tile, context, "Chicken").length;
      return adjScore - minus;
    },
  },

  // 🐺
  Wolf: {
    name: "Wolf",
    description: "+1 point per adjacent Nature and Animal tile. -2 points per adjacent Vehicle tile. Other players can't place Person tiles adjacent to this.",
    image: "/game/wolf.png",
    backgroundColor: null,
    tags: [TileTag.Animal, TileTag.Protective],
    requires: [[TileTag.Nature, TileTag.Animal]],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return (
        getTilesWhere(tile, context, TileTag.Animal).length +
        getTilesWhere(tile, context, TileTag.Nature).length -
        getTilesWhere(tile, context, TileTag.Vehicle).length * 2
      );
    },
  },

  // 👮
  Police: {
    name: "Police",
    description: "+1 point per adjacent Destructive tile. Adjacent Destructive tiles acquires -2 points.",
    image: "/game/police.png",
    backgroundColor: null,
    tags: [TileTag.Person, TileTag.Protective],
    requires: [TileTag.Destructive],
    calculateScore: (tile: Tile, context: TileContext) => {
      return getTilesWhere(tile, context, TileTag.Destructive).length;
    },
  },

  // 📷
  Photographer: {
    name: "Photographer",
    description: "When placed: Gets half the points of the adjacent tile with the least amount of calculated points (rounded down). This amount does not change even if the adjacent tile's points change.",
    image: "/game/camera.png",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [],
    calculateScore: (__: Tile, _: TileContext) => 0,
    onPlace: (move: Move, world: World) => {
      const newWorld = copyWorld(world);
      let amount = -Infinity;
      const tile = newWorld.tiles[move.index];
      // Replace with Empty to get calculateScoreFor as it was before putting out tile
      tile.content = 'Empty';
      for (let adj of getAdjacentTilesTo(tile, { world: newWorld })) {
        const adjTilePoints = calculateScoreFor(adj, { world: newWorld });
        amount = Math.max(adjTilePoints, amount);
      }
      if (amount > -Infinity)
        tile.additionalPoints = Math.floor(amount / 2);
      // Restore back from setting to Empty from earlier
      tile.content = 'Photographer';
      return newWorld;
    },
  },

  // 🚒
  Firetruck: {
    name: "Firetruck",
    description: "Gets points equal to the number of adjacent Water tiles multiplied with the number of adjacent Building and Forest tiles.",
    image: "/game/fire-truck.png",
    backgroundColor: null,
    tags: [TileTag.Vehicle],
    requires: [TileTag.Water, [TileTag.Building, TileTag.Forest]],
    calculateScore: function (tile: Tile, context: TileContext): number {
      const waters = getTilesWhere(tile, context, TileTag.Water).length;
      const trees = getTilesWhere(tile, context, TileTag.Forest).length;
      const buildings = getTilesWhere(tile, context, TileTag.Building).length;
      return waters * (trees + buildings);
    },
  },

  // 🚀
  Rocket: {
    name: "Rocket",
    description: "+12 points if there are exactly one adjacent Rocket tile, otherwise 0 points.",
    image: "/game/rocket.png",
    backgroundColor: null,
    tags: [TileTag.Vehicle, TileTag.Tall, TileTag.Flying],
    requires: [],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return getTilesWhere(tile, context, "Rocket").length === 1 ? 12 : 0;
    },
  },

  // 🦖
  T_Rex: {
    name: "T-Rex",
    description: "+1 point per Person tile placed exactly two tiles away. On place: Adjacent Person tiles aquires -1 point and if possible moves one step away from the placed T-Rex.",
    image: "/game/t-rex.png",
    backgroundColor: null,
    tags: [TileTag.Animal, TileTag.Tall, TileTag.Destructive],
    requires: [TileTag.Person],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return getTilesWithDistanceTo(tile, context, 2, 2).filter(t => tileCheck(t, TileTag.Person)).length;
    },
    onPlace: (move: Move, world: World) => {

      const newWorld = copyWorld(world);

      for (let direction of [
        { x: -1, y: 1 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: -1, y: 0 },
        { x: 1, y: 0 },
        { x: -1, y: -1 },
        { x: 0, y: -1 },
        { x: 1, y: -1 },
      ]) {
        const adjInDirectionCoord = { x: move.x + direction.x, y: move.y + direction.y };
        const adjInDirection = getTileAt({ world: newWorld }, adjInDirectionCoord.x, adjInDirectionCoord.y);
        if (adjInDirection === null)
          continue;

        if (tileCheck(adjInDirection, TileTag.Person)) {
          // Add negative points to adjacent persontiles
          adjInDirection.additionalPoints -= 1;

          const adjTwoStepsInDirectionCoord = { x: adjInDirectionCoord.x + direction.x, y: adjInDirectionCoord.y + direction.y };
          const adjTwoStepsInDirection = getTileAt({ world: newWorld }, adjTwoStepsInDirectionCoord.x, adjTwoStepsInDirectionCoord.y);
          if (adjTwoStepsInDirection === null)
            continue;

          // If person tile should be able to move
          if (tileCheck(adjTwoStepsInDirection, "Empty")) {
            swapTiles(adjInDirection, adjTwoStepsInDirection);
          }
        }

      }
      return newWorld;
    },
  },

  // 🎨
  Painter: {
    name: "Painter",
    description: "+1 point per adjacent and unique tile.",
    image: "/game/painter.png",
    backgroundColor: null,
    tags: [
      TileTag.Person
    ],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      // Get all adjacent tiles
      const adj = getAdjacentTilesTo(tile, context);
      // Filter out empty tiles
      let adjNoEmpty = adj.filter((p) => !tileCheck(p, "Empty"));
      // Get list of all contents
      let a: TileContent[] = adjNoEmpty.map((a) => {
        return a.content;
      });
      // Check unique contents using a Set
      const numUnique = new Set(a).size;
      return numUnique;
    },
  },

  // 🐱‍👤
  Ninja: {
    name: "Ninja",
    description: "+1 point per adjacent Person and Animal tile. Halves the points of adjacent Person and Animal tiles (rounded down)",
    image: "/game/ninja.png",
    backgroundColor: null,
    tags: [TileTag.Person, TileTag.Destructive],
    requires: [[TileTag.Person, TileTag.Animal]],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return getTilesWhere(tile, context, TileTag.Person).length
        + getTilesWhere(tile, context, TileTag.Animal).length;
    },
  },

  // 🧝‍♀️
  Elf: {
    name: "Elf",
    description: "+1 point per adjacent Forest. Converts adjacent Deserts and Mountains into Forest.",
    image: "/game/elf.png",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [[TileTag.Desert, TileTag.Mountain]],
    calculateScore: function (tile: Tile, context: TileContext): number {
      return getTilesWhere(tile, context, TileTag.Forest).length
    },
    onPlace: (move: Move, world: World) => {
      const newWorld = copyWorld(world);
      const tile = newWorld.tiles[move.index];
      for (let adj of getAdjacentTilesTo(tile, { world: newWorld })) {
        if (tileCheck(adj, TileTag.Mountain) || tileCheck(adj, TileTag.Desert)) {
          adj.content = "Forest";
        }
      }
      return newWorld;
    },
  },

  // 👹
  Giant: {
    name: "Giant",
    description: "When placed: Makes adjacent Mountains give -1, if not negative already. Adjacent negative nature gives positive points.",
    image: "/game/giant.png",
    backgroundColor: null,
    tags: [TileTag.Person, TileTag.Destructive, TileTag.Tall],
    requires: [TileTag.Mountain],
    calculateScore: (__: Tile, _: TileContext) => 0,
    onPlace: (move: Move, world: World) => {
      const newWorld = copyWorld(world);
      const tile = newWorld.tiles[move.index];
      for (let adj of getAdjacentTilesTo(tile, { world: newWorld })) {
        if (tileCheck(adj, TileTag.Mountain) && adj.additionalPoints >= 0) {
          adj.additionalPoints = -1;
        }
      }
      return newWorld;
    },
  },

  // 🔭
  Telescope: {
    name: "Telescope",
    description: "+1 point per Flying and Tall tag of adjacent tiles.",
    image: "/game/telescope.png",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {

      return getTilesWhere(tile, context, TileTag.Flying).length
        + getTilesWhere(tile, context, TileTag.Tall).length;
    },
  },

  // 🦾
  RobotArm: {
    name: "Robot Arm",
    description: "When placed: Swaps the top tile with the bottom tile and the left tile with the right tile. Swapping with the edge does nothing.",
    image: "/game/robot-arm.png",
    backgroundColor: null,
    tags: [TileTag.Building],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      return 0;
    },
    onPlace: (move: Move, world: World) => {
      const newWorld = copyWorld(world);

      const top = getTileAt({ world: newWorld }, move.x, move.y - 1);
      const bottom = getTileAt({ world: newWorld }, move.x, move.y + 1);
      swapTiles(top, bottom);

      const left = getTileAt({ world: newWorld }, move.x - 1, move.y);
      const right = getTileAt({ world: newWorld }, move.x + 1, move.y);
      swapTiles(left, right);

      return newWorld;
    }
  },

  // 🐟
  Fish: {
    name: "Fish",
    description: "+10 points, but -1 point per Water tile not adjacent to this. This counts as a Water tile.",
    image: "/game/fish.png",
    backgroundColor: null,
    tags: [TileTag.Animal, TileTag.Water],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      const adj = getAdjacentTilesTo(tile, context);
      return 10 - context.world.tiles.filter(w => w !== tile && tileCheck(w, TileTag.Water) && !adj.includes(w)).length;
    },
  },

  // 🏚️
  RundownHouse: {
    name: "Run-down House",
    description: "+1 point per Adjacent Building tile. Adjacent Building tiles acquires -1 point.",
    image: "/game/broken-house.png",
    backgroundColor: null,
    tags: [TileTag.Building, TileTag.Destructive],
    requires: [TileTag.Building],
    calculateScore: (tile: Tile, context: TileContext) => {
      return getTilesWhere(tile, context, TileTag.Building).length;
    },
  },

  // 🧟‍♂️
  Zombie: {
    name: "Zombie",
    description: "+1 point per Person orthogonal to this. Makes orthogonal Person tiles into Zombie. Adjacent negative nature gives positive points.",
    image: "/game/zombie.png",
    backgroundColor: null,
    tags: [TileTag.Person, TileTag.Destructive],
    requires: [TileTag.Person],
    calculateScore: (tile: Tile, context: TileContext) => {
      return getTilesWhere(tile, context, TileTag.Person, getOrthogonalTilesTo).length;
    },
    onPlace: function (move: Move, world: World): World {
      const newWorld = copyWorld(world);
      const tile = newWorld.tiles[move.index];
      for (let adj of getOrthogonalTilesTo(tile, { world: newWorld })) {
        if (tileCheck(adj, TileTag.Person)) {
          adj.content = "Zombie";
        }
      }
      return newWorld;
    }
  },

  // 🧚‍♀️
  Fairy: {
    name: "Fairy",
    description: "On place: Turns all orthogonal Destructive tiles into National Parks",
    image: "/game/fairy.png",
    backgroundColor: null,
    tags: [TileTag.Person, TileTag.Protective],
    requires: [TileTag.Destructive],
    calculateScore: (tile: Tile, context: TileContext) => {
      return 0;
    },
    onPlace: function (move: Move, world: World): World {
      const newWorld = copyWorld(world);
      const tile = newWorld.tiles[move.index];
      for (let adj of getOrthogonalTilesTo(tile, { world: newWorld })) {
        if (tileCheck(adj, TileTag.Destructive)) {
          adj.content = "NationalPark";
          adj.additionalPoints = 0;
          adj.player = null;
        }
      }
      return newWorld;
    }
  },

  // // 🧱
  // Wall: {
  //   name: "Wall",
  //   description: "+3 points per Wall in group only if this has at most 1 orthogonal Wall. Can't place Wall next to two orthogonal Walls.",
  //   image: "/game/wall.png",
  //   backgroundColor: null,
  //   tags: [TileTag.Building],
  //   requires: [],
  //   calculateScore: (tile: Tile, context: TileContext) => {
  //     console.log('1', getOrthogonalTilesTo(tile, context).filter(t => t.content === 'Wall').length);
  //     console.log('2', getAdjacentGroupsOfTiles((w: Tile, s?: Tile) => s && s.content === 'Wall', tile, context, true).length);
  //     return getOrthogonalTilesTo(tile, context).filter(t => t.content === 'Wall').length < 2 ? 3 * getAdjacentGroupsOfTiles((w: Tile, s?: Tile) => w.content === 'Wall', tile, context, true).length : 0;
  //   },
  // },

  // 👶
  Baby: {
    name: "Baby",
    description: "Crawles one step away from tiles placed adjacent to this. +1 point per crawl. Cannot crawl over nature.",
    image: "/game/baby.png",
    backgroundColor: null,
    tags: [TileTag.Person],
    requires: [],
    calculateScore: (tile: Tile, context: TileContext) => {
      return 0;
    },
    onPlaceAdjacent(move, xOffset, yOffset, world): World {
      const newWorld = copyWorld(world);
      const myself = getTileAt({ world: newWorld }, move.x + xOffset, move.y + yOffset);
      const tileInDirection = getTileAt({ world: newWorld }, move.x + xOffset * 2, move.y + yOffset * 2);

      // Abort if nature
      if (tileInDirection && getTileProps(tileInDirection.content).tags.includes(TileTag.Nature)) 
      {
        return world;
      }
      // If both tiles are good, get points and swap.
      if (myself && tileInDirection) myself.additionalPoints++;
      swapTiles(myself, tileInDirection);

      return newWorld;
    },
  },
};

export function getTileProps(content: TileContent): TileProps {
  return tileProps[content] as TileProps;
}
