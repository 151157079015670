import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import { Room } from "@tilescape/common";
import QRCode from "react-qr-code";

type Props = {
  room: Room;
  open: boolean;
  onClose: () => void;
};

export default function ShareDialog({ room, open, onClose }: Props) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Invite with QR Code
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          pb: 3,
        }}
      >
        <QRCode value={window.location.toString()} />
      </Box>
    </Dialog>
  );
}
