import { Close as CloseIcon } from "@mui/icons-material";
import {
    Box, Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, Typography
} from "@mui/material";

type Props = {
    title: string;
    text: string;
    open: boolean;
    onClose: () => void;
    onYes: () => void;
};

export default function AreYouSureDialog({ title, text, open, onClose, onYes }: Props) {

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ textAlign: "center" }} variant="h5">
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        pb: 3,
                    }}
                >
                    <Typography>{text}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => onYes()}>Yes</Button>
                <Button variant="contained" onClick={() => onClose()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
