import { getTileProps, Move, Player, Tile, TileContentBuilding, TileTag, World } from "../game";
import { getAdjacentTilesTo } from "./score";

export function copyWorld(world: World): World {
    return {
        tiles: world.tiles.map((tile) => {
            return { ...tile };
        }),
        width: world.width,
        height: world.height,
    };
}

export function swapTiles(tile1: Tile, tile2: Tile): boolean {
    if (tile1 && tile2) {
        const tmpContent = tile1.content;
        const tmpAdditionalPoints = tile1.additionalPoints;
        const tmpPlayer = tile1.player;

        tile1.content = tile2.content;
        tile1.additionalPoints = tile2.additionalPoints;
        tile1.player = tile2.player;

        tile2.content = tmpContent;
        tile2.additionalPoints = tmpAdditionalPoints;
        tile2.player = tmpPlayer;

        return true;
    }
    return false;
}

export function canPlace(player: Player, content: TileContentBuilding, x: number, y: number, world: World): boolean {
    const tile = world.tiles[y * world.width + x];
    if (tile.content !== "Empty" || tile.player !== null) return false;

    const props = getTileProps(content);
    const adj = getAdjacentTilesTo(tile, { world: world, });

    // Prevent placement of Destutive tiles next to church
    if (props.tags.includes(TileTag.Destructive) && adj.filter((b) => b.content === "Church").length > 0) {
        return false;
    }
    // Prevent placement of Person tiles next to another players wolf
    else if (props.tags.includes(TileTag.Person) && adj.filter((b) => b.content === "Wolf" && b.player?.id !== player.id).length > 0) {
        return false;
    }

    return true;
}