import { Box, Paper } from "@mui/material";
import {
  ClientToServerEvents,
  getRandomPossiblePlayerColor,
  getRandomUsername,
  MessageCode,
  Room,
  RoomSettings,
  ServerToClientEvents,
  SessionDetails,
  TileContentBuilding,
  UserSettings,
} from "@tilescape/common";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import io, { Socket } from "socket.io-client";
import AppStyler from "./AppStyler";
import TopBar from "./component/AppBar/AppBar";
import Disconnected from "./component/Disconnected";
import Loading from "./component/Loader";
import { AppContextProvider } from "./context/AppContext";
import useLocalStorage from "./hooks/useLocalStorage";

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  `https://tilescape-server.linjord.dev/`,
  {
    autoConnect: false,
  }
);

function App() {
  const navigate = useNavigate();

  const [visit, setVisit] = useLocalStorage("visit", false);
  const [firstTimeVisit] = useState<boolean>(!visit);

  const [username, setUsername] = useLocalStorage<string>(
    "username",
    getRandomUsername()
  );
  const [color, setColor] = useLocalStorage<string>(
    "color",
    getRandomPossiblePlayerColor()
  );
  const [sessionID, setSessionID] = useLocalStorage<string>("sessionID", "");
  const [userID, setUserID] = useLocalStorage<string>("userID", "");

  const [connected, setConnected] = useState(socket.connected);
  const [disconnectReason, setDisconnectReason] =
    useState<Socket.DisconnectReason | null>(null);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [room, setRoom] = useState<Room | null>(null);

  const api: ClientToServerEvents = {
    updateUserSettings: (settings: UserSettings) =>
      socket.emit("updateUserSettings", settings),
    requestAllRooms: () => socket.emit("requestAllRooms"),
    updateRoomSettings: (settings: RoomSettings) =>
      socket.emit("updateRoomSettings", settings),
    joinRoom: (id: string) => socket.emit("joinRoom", id),
    leaveRoom: () => socket.emit("leaveRoom"),
    createRoom: (name: string) => socket.emit("createRoom", name),
    startRoomPlay: () => socket.emit("startRoomPlay"),
    newRandomGame: () => socket.emit("newRandomGame"),
    finishedRoomBackToLobby: () => socket.emit("finishedRoomBackToLobby"),
    placeBuilding: (building: TileContentBuilding, x: number, y: number) =>
      socket.emit("placeBuilding", building, x, y),
    kickPlayer: (id: string) => socket.emit("kickPlayer", id),
    updateRoomName: (name: string) => socket.emit("updateRoomName", name),
    randomizeRoomTiles: () => socket.emit("randomizeRoomTiles"),
    addBotToRoom: () => socket.emit("addBotToRoom"),
  };

  const sendMyUserSettingsToServer = () => {
    api.updateUserSettings({ name: username, color: color });
  };

  useEffect(() => {
    setVisit(true);

    socket.on("connect", () => {
      setConnected(true);
      sendMyUserSettingsToServer();
    });

    socket.on("disconnect", (reason: Socket.DisconnectReason) => {
      setConnected(false);
      setDisconnectReason(reason);
    });

    socket.on("session", (session: SessionDetails) => {
      // attach the session ID to the next reconnection attempts
      socket.auth = { sessionID: session.sessionID };
      // store it in the localStorage
      setSessionID(session.sessionID);
      // save the ID of the user
      setUserID(session.userID);
    });

    socket.on("sendAvailableRooms", (rooms: Room[]) => {
      setRooms(rooms);
    });

    socket.on("sendJoinRoom", (room: Room) => {
      setRoom(room);
      navigate("/room/" + room.id);
    });

    socket.on("roomUpdate", (room: Room) => {
      setRoom(room);
    });

    socket.on("message", (message: MessageCode) => {
      if (message === 10) {
        toast("The room has already started");
        navigate("/rooms");
      } else if (message === 20) {
        toast("You got kicked");
        navigate("/rooms");
      } else if (message === 30) {
        toast("The room does not exist");
        navigate("/rooms");
      } else if (message === 40) {
        toast("You are already part of a room");
      } else if (message === 50) {
        toast("The room is full capacity");
        navigate("/rooms");
      }
    });

    if (sessionID) socket.auth = { sessionID };
    socket.connect();

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("session");
      socket.off("sendAvailableRooms");
      socket.off("sendJoinRoom");
      socket.off("roomUpdate");
      socket.off("message");
    };
  }, []);

  return (
    <FullWidth>
      <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
        <Box sx={{ width: 1600 }}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: 'url("/app/background.png")',
              backgroundPosition: "0 56px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              boxShadow: "inset 0 0 20px 2px #000000",
            }}
          >
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <AppStyler>
              <AppContextProvider
                value={{
                  connectionId: userID,
                  connected: connected,
                  rooms: rooms,
                  room: room,
                  api: api,
                  local: {
                    firstTimeVisit: firstTimeVisit,
                    username: username,
                    setUsername: setUsername,
                    color: color,
                    setColor: setColor,
                  },
                }}
              >
                {connected ? (
                  <>
                    <TopBar />
                  </>
                ) : disconnectReason != null ? (
                  <Disconnected />
                ) : (
                  <Loading />
                )}
              </AppContextProvider>
            </AppStyler>
          </Paper>
        </Box>
      </Box>
    </FullWidth>
  );
}

function FullWidth({ children }: React.PropsWithChildren<{}>) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {children}
    </div>
  );
}

export default App;
