import { AppBar, Box, Button, Card, Container, CssBaseline, Divider, Stack, Toolbar, Typography } from "@mui/material";
import PowerOffIcon from '@mui/icons-material/PowerOff';

type Props = {
}

export default function Disconnected({ }: Props) {
    return (
        <>
            <CssBaseline />
            <AppBar>
                <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack direction="row" spacing={.5} sx={{ border: `1px solid dimgrey`, padding: .5, borderRadius: 2 }}>
                        <img style={{ marginTop: 1 }} alt="logo" src="/app/logo.png" width={30} height={30} />
                        <Typography variant="h6" component="div">
                            ilescape.io
                        </Typography>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container>
                <Card sx={{ mt: 2, p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <PowerOffIcon fontSize="large" />
                    <Typography sx={{ p: 2, pt: 1 }} variant="h6">
                        Server connection was lost or could not be established.
                    </Typography>
                </Card>
            </Container>
        </>
    );
}