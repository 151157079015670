import { Box, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import { getTileProps, TileContentBuilding } from "@tilescape/common";
import { tagToIcon } from "src/util/tag";

type Props = {
  tiles: TileContentBuilding[];
  highlightSelected?: boolean;
  pointerCursor: boolean;
  onSelectTile: (tile: TileContentBuilding, index: number) => void;
  hidden: boolean;
};

export function TilePickerRow({ tiles, highlightSelected, pointerCursor, onSelectTile, hidden }: Props) {
  const [selectedItem, setSelectedItem] = useState<TileContentBuilding>(tiles[0]);

  const { palette } = useTheme();

  return (
    <Grid container columns={tiles.length}>
      {tiles.map((building: TileContentBuilding, index: number) => {
        const tileProps = getTileProps(building);

        return (
          <Grid
            item
            key={building}
            xs={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              onClick={() => {
                setSelectedItem(building);
                onSelectTile(building, index);
              }}
              sx={{
                borderRadius: 2,
                border: selectedItem === building && highlightSelected ? "5px solid white" : hidden ? `2px solid ${palette.primary.main}` : null,
                cursor: !hidden && pointerCursor ? "pointer" : null,
              }}
            >
              {hidden ?
                <img src={'/game/hidden.png'} width={"100%"} height={"100%"} />
                :
                <img src={tileProps.image} width={"100%"} height={"100%"} alt={building} />}
            </Box>
            <Box
              sx={{
                color: "lightgrey",
                flexWrap: "nowrap",
                display: "flex",
              }}
            >
              {!hidden ? tileProps.tags.map((tag, index) => (
                <React.Fragment key={index}>{tagToIcon(tag, tileProps.tags.length >= 4)}</React.Fragment>
              )) : null}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
