import { AppBar, Box, CircularProgress, Container, CssBaseline, Skeleton, Stack, Toolbar, Typography } from "@mui/material";

export default function Loading() {
    return (
        <>
            <CssBaseline />
            <AppBar>
                <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack direction="row" spacing={.5} sx={{ border: `1px solid dimgrey`, padding: .5, borderRadius: 2 }}>
                        <img style={{ marginTop: 1 }} alt="logo" src="/app/logo.png" width={30} height={30} />
                        <Typography variant="h6" component="div">
                            ilescape.io
                        </Typography>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Container>
                <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <CircularProgress size={100} />
                </Box>;
            </Container>
        </>);
}