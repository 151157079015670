import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Box, Typography, IconButton, Grid } from "@mui/material";
import { ALL_TILECONTENT, getTileProps, TileContentBuilding } from "@tilescape/common";

type Props = {
  open: boolean;
  onClose: () => void;
  onTileSelect: (tile: TileContentBuilding) => void;
  selectedTiles: TileContentBuilding[];
};

export default function TileSelectDialog({ open, onClose, onTileSelect, selectedTiles }: Props) {
  return (
    <Dialog open={open} onClose={() => onClose()} keepMounted fullWidth maxWidth="md">
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Select Tile
          </Typography>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box sx={{ p: 1 }}>
        <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {ALL_TILECONTENT.BUILDING.filter(b => !selectedTiles.includes(b)).map((building) => {
            return (
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                key={building}
              >
                <Box
                  onClick={() => onTileSelect(building)}
                  sx={{
                    p: 1,
                    "&:hover": {
                      cursor: 'pointer',
                      borderRadius: 2,
                      backgroundColor: "dimgrey",
                    },
                  }}
                >
                  <img src={getTileProps(building).image} width={75} height={75} alt={building} />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Dialog>
  );
}
