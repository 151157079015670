import { Navigate, Route, Routes } from "react-router-dom";
import RoomList from "./pages/RoomList/RoomList";
import Room from "./pages/Room/Room";
import App from "./App";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to="/rooms" />} />
        <Route path="/rooms" element={<RoomList />} />
        <Route path="/room/:roomId" element={<Room />} />
      </Route>
    </Routes>
  );
}
