import { ALL_TILECONTENT, getTileProps, isTileTagNature, TileContent, TileContentBuilding } from "../game";
import { shuffleArray } from "./func";

/**
 * Return whether candiate fulfills the tags in requester's required array.
 */
export function fulfillsRequired(requester: TileContentBuilding, candiate: TileContentBuilding): boolean {
    const recentTileRequires = getTileProps(requester).requires.map(p => {
        if (Array.isArray(p)) return p.filter(p => !isTileTagNature(p))
        else return p;
    }).filter(p => Array.isArray(p) && p.length > 0 || (!Array.isArray(p) && !isTileTagNature(p)));
    const tags = getTileProps(candiate).tags;
    // Go through all required tags
    for (let req of recentTileRequires) {
        if (Array.isArray(req)) {
            // Check OR
            let or = false;
            for (let reqTag of req) {
                if (isTileTagNature(reqTag)) {
                    continue;
                }
                // Any tag in the list? Set OR is true
                if (tags.includes(reqTag)) or = true;
            }
            if (!or) return false;
        } else {
            if (isTileTagNature(req)) {
                continue;
            }
            if (!tags.includes(req)) {
                return false;
            }
        }
    }
    return true;
}

/**
 * Generates one tile to start with and each next tile must fullfill the previous tile's required list.
 * If no remaining tile can fulfill the required list, a next tile is selected at random.
 */
export function getRandomBalancedTiles(tileAmount: number): TileContentBuilding[] {
    const tiles = shuffleArray([...ALL_TILECONTENT.BUILDING]).slice(0, 1);
    while (tiles.length < tileAmount) {
        const recentTile = tiles[tiles.length - 1];
        const availableTiles = shuffleArray([...ALL_TILECONTENT.BUILDING]).filter(t => !tiles.includes(t));
        let nextTile = null;
        availableTiles.some(candiate => {
            let fulfillPrev = fulfillsRequired(recentTile as TileContentBuilding, candiate);
            // If last tile, then check if anybody fulfills my requirements
            if (tiles.length === tileAmount - 1) {
                if (!fulfillPrev) return false;
                let isgood = tiles.some(t => fulfillsRequired(candiate, t as TileContentBuilding));
                fulfillPrev = fulfillPrev && isgood;
            }
            if (fulfillPrev) {
                nextTile = candiate;
                return true;
            }
            return false;
        });
        tiles.push(nextTile ?? availableTiles[0]);
    }
    return tiles as TileContentBuilding[];
}