import FaceIcon from "@mui/icons-material/Face";
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";
import UserSettingsDialog from "./subcomponents/UserSettingsDialog";
import HelpIcon from "@mui/icons-material/Help";
import BuildingHelpDialog from "./subcomponents/BuildingHelpDialog";

export default function TopBar() {
  const context = useAppContext();
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);

  const matches700 = useMediaQuery("(min-width:700px)");
  const matches500 = useMediaQuery("(min-width:500px)");

  const name = context.local.username;

  return (
    <>
      <UserSettingsDialog
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />
      <BuildingHelpDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
      <CssBaseline />
      <AppBar>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ border: `1px solid dimgrey`, padding: 0.5, borderRadius: 2 }}
          >
            <img
              style={{ marginTop: 1 }}
              alt="logo"
              src="/app/logo.png"
              width={30}
              height={30}
            />
            {matches700 ? (
              <Typography variant="h6" component="div">
                ilescape
              </Typography>
            ) : null}
          </Stack>
          <Typography variant="h6" component="div">
            <Stack
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
              spacing={1}
            >
              <Box
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  backgroundColor: context.local.color,
                }}
              />
              {!matches500 ? (
                <Typography variant="h6">
                  {name.length > 10 ? name.substring(0, 10) + "..." : name}
                </Typography>
              ) : (
                <Typography variant="h6">{name}</Typography>
              )}
            </Stack>
          </Typography>
          <Stack direction="row" spacing={0.5}>
            <Button variant="contained" onClick={() => setHelpOpen(true)}>
              <HelpIcon />
            </Button>
            <Button
              disabled={
                context.room !== null && context.room.state === "Playing"
              }
              variant="contained"
              onClick={() => setSettingsOpen(true)}
            >
              <FaceIcon />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        <Outlet />
      </Container>
    </>
  );
}
