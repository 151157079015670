import { Button, CircularProgress, Dialog, DialogTitle, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useAppContext } from "src/context/AppContext";

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
}

export default function RenameRoomDialog({ name, open, onClose }: Props) {

  const context = useAppContext();
  const [roomName, setRoomName] = useState<string>(name);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => handleClose()} open={open}>
      <DialogTitle>Enter Room Name</DialogTitle>
      <TextField inputProps={{ maxLength: 20 }} placeholder="Room Name..." sx={{ m: 1 }} variant="outlined" value={roomName} onChange={(event) => setRoomName(event.target.value)} />
      <Button sx={{ m: 1, height: 50 }} variant="contained" onClick={() => {
        if (roomName !== '') {
          context.api.updateRoomName(roomName);
          handleClose();
        }
      }}>{<Typography>Edit</Typography>}</Button>
    </Dialog>
  );
}
