import { Box, Grid } from "@mui/material";
import { TileProps, getTileProps, calcTotalScoreFor, Move, Room, Tile } from "@tilescape/common";
import { useEffect, useState } from "react";

type Props = {
  room: Room;
  onClick: (tile: Tile) => void;
};

export default function GameBoard({ room, onClick }: Props) {
  const settings = room.settings;
  const state = room.gameState;

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = (_: UIEvent) => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImage = (tile: Tile, tileProps: TileProps): string => {
    if (tile.additionalPoints > 0 && tileProps.imagePositive) return tileProps.imagePositive;
    else if (tile.additionalPoints < 0 && tileProps.imageNegative) return tileProps.imageNegative;
    return tileProps.image;
  };

  const getScoreToShowInCorner = (score: number, tile: Tile): string | null => {
    if (tile.player !== null) return score === 0 ? null : score.toString();
    if (tile.player === null && tile.additionalPoints !== 0 && tile.content !== "Empty") {
      let text = tile.additionalPoints.toString();
      if (tile.additionalPoints > 0) text = "+" + text;
      return text;
    }
    return null;
  };

  const lastMove: Move | null = state.moves.length > 0 ? state.moves[state.moves.length - 1] : null;

  return (
    <>
      <Grid
        container
        columns={settings.width}
        sx={{
          backdropFilter: "blur(15px)",
          backgroundColor: "#FFFFFFCC",
          display: "flex",
          padding: 0,
          margin: 0,
          justifyContent: "space-around",
          minWidth: "50px",
          maxWidth: "600px",
        }}
      >
        {state.world.tiles.map((tile: Tile, index: number) => {
          const tileProps = getTileProps(tile.content);
          const score = calcTotalScoreFor(tile, {
            world: room.gameState.world,
          });

          const getPadding = () => {
            if (windowWidth > 1600) return 1.75;
            else if (windowWidth > 500) return 1.25;
            else if (windowWidth > 100) return 1;
            else return 0;
          };

          const isLastMove = (): boolean => {
            if (!lastMove) return false;
            return lastMove.x === tile.x && lastMove.y === tile.y;
          };

          return (
            <Grid item key={index} xs={1}>
              <Box
                onClick={() => onClick(tile)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: getPadding(),
                  textAlign: "center",
                  aspectRatio: "1 / 1",
                  position: "relative",
                  border: isLastMove() ? `5px dashed dimgrey` : "1px solid dimgrey",

                  backgroundColor: tile.player === null ? tileProps.backgroundColor : null,
                  boxShadow: tile.player === null ? "" : `inset 0px 0px 20px 5px ${tile.player.color}`,
                  "&:hover": {
                    backgroundColor: tile.content === "Empty" ? "darkgrey" : null,
                  },
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 3,
                    color: "black",
                    fontSize: windowWidth > 500 ? 25 : 16,
                  }}
                >
                  {getScoreToShowInCorner(score, tile)}
                </span>
                <img src={getImage(tile, tileProps)} width="100%" height="100%" alt={tileProps.name} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
