import { useAppContext } from "src/context/AppContext";
import useAudio from "src/hooks/useAudio";
import {
  Add as AddIcon,
  ArrowForwardIos as PlayIcon,
  People as PeopleIcon,
  Psychology,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Fab,
  Grid,
  makeStyles,
  Stack,
  Typography,
} from "@mui/material";
import { Room } from "@tilescape/common";
import { useEffect, useState } from "react";
import CreditsDialog from "./subcomponents/CreditsDialog";
import { useNavigationType } from "react-router-dom";

export default function RoomList() {
  const context = useAppContext();
  const navType = useNavigationType();
  const rooms = context.rooms;
  const defaultRoomName = context.local.username + "'s room";

  const [creditsOpen, setCreditsOpen] = useState<boolean>(false);
  const [_, toggle] = useAudio("/audio/join.wav");

  useEffect(() => {
    if (navType === "POP") {
      context.api.leaveRoom();
    }
  }, [navType]);

  useEffect(() => {
    context.api.requestAllRooms();
  }, []);

  const IsMeInsideRoom = (room: Room) => {
    return room.players.filter((p) => p.id === context.connectionId).length > 0;
  };

  // 1. Hide rooms that I'm currently in. This is to not have a glitch when refreshing,
  //    because fetching rooms taking some time.
  // 2. Hide rooms on endscreen

  const filteredRooms = rooms.filter(
    (room) => !IsMeInsideRoom(room) && room.state !== "Finished"
  );

  return (
    <>
      <CreditsDialog open={creditsOpen} onClose={() => setCreditsOpen(false)} />
      <Box sx={{ my: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => context.api.createRoom(defaultRoomName)}
          >
            Create Room
          </Button>
        </Box>
        <Divider
          sx={{
            p: 1,
            "&::before, &::after": {
              borderColor: "success.dark",
            },
          }}
        >
          OR
        </Divider>
        {filteredRooms.length === 0 && (
          <Card
            sx={{
              textAlign: "center",
              backgroundColor: "#12121299",
              "& .MuiPaper-root": {
                backdropFilter: "blur(8px)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: -1 }}>
                No rooms available
              </Typography>
            </CardContent>
          </Card>
        )}
        {filteredRooms.map((room, index) => {
          const numPlayers = room.players.length;
          const numBotPlayers = room.players.filter((p) => p.isBot).length;

          return (
            <Card key={index} sx={{ mt: 1 }} elevation={3}>
              <CardContent sx={{ position: "relative", width: "100%" }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={8}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="h4">{room.name}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <PeopleIcon fontSize="small" />
                      <Typography variant="body1">
                        {numPlayers} {numPlayers === 1 ? "player" : "players"}
                      </Typography>
                      {numBotPlayers > 0 ? (
                        <>
                          <Typography
                            variant="body1"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            ( {numBotPlayers}{" "}
                            <Psychology
                              style={{ marginTop: -1, marginRight: 1 }}
                              fontSize="small"
                            />{" "}
                            )
                          </Typography>
                        </>
                      ) : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <JoinButton
                      room={room}
                      onClick={() => {
                        toggle();
                        context.api.joinRoom(room.id);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </Box>
      <Fab
        onClick={() => context.api.createRoom(defaultRoomName)}
        size="large"
        color="primary"
        sx={{
          height: 75,
          width: 75,
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon style={{ fontSize: 50 }} />
      </Fab>
      <Button
        sx={{
          position: "fixed",
          bottom: 8,
          left: 8,
        }}
        onClick={() => setCreditsOpen(true)}
      >
        About
      </Button>
    </>
  );
}

function JoinButton({ room, onClick }: { room: Room; onClick: () => void }) {
  if (room.players.length >= room.settings.maxPlayers) {
    return (
      <Button disabled fullWidth variant="contained">
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1">Full</Typography>
        </Stack>
      </Button>
    );
  } else if (room.state === "Waiting") {
    return (
      <Button onClick={() => onClick()} fullWidth variant="contained">
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1">Join</Typography>
          <PlayIcon fontSize="small" />
        </Stack>
      </Button>
    );
  } else if (room.state === "Playing") {
    return (
      <Button disabled fullWidth variant="contained">
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body1">Playing</Typography>
        </Stack>
      </Button>
    );
  }

  return null;
}
