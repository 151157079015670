
let usernameList = [
    "Husky",
    "Acrobat",
    "Trolley",
    "Popular-Boy",
    "Tetrisman",
    "Pacman-bro",
    "Harmonic-player",
    "Funny-bunny",
    "Brother",
    "Winner",
    "Champion",
    "Racer",
    "Outsmarter",
    "Competitor",
    "I Love tilescape.io",
    "untitled",
    "Easteregg",
    "Trumpsupporter",
    "Minecrafter",
    "Diamondplayer",
    "Best Player Ever",
    "Unusual Name",
    "Everyone Rename to This",
    "Gamer",
    "Better than you",
    "Crazy",
    "Crocodile",
    "Marsmellow",
    "Sweetheart",
    "Panda",
    "Penguin",
    "Polarbear",
    "Speedplayer",
    "My password is 1234",
    "Spongebob",
    "Squidward",
    "Patrick",
    "Mr. Krabs",
    "Elon Musk",
    "Jeff Bezos",
    "Elephant",
    "Ninja-player",
    "Electron",
    "Pro-player",
    "Snowman",
    "Snowflake",
    "Quickplayer",
    "Slowplayer",
    "Suprised",
    "HappyClappy",
    "Sad & Mad",
    "Genius",
    "Perfectionist",
    "Champion",
    "Starman",
    "Mr. Fantastic",
    "Ironman",
    "Spiderman",
    "Batman",
    "Superman",
    "Good Morning Everyone",
    "Happy Birthday",
    "It's Christmas Time",
]

export function getRandomUsername() {
    return usernameList[Math.floor(Math.random() * usernameList.length)]
}
