import {
    DoNotDisturb,
    Flight,
    HeartBroken,
    Home,
    Landscape,
    LightMode,
    Park,
    Person,
    Pets,
    Shield,
    Spa,
    Straight,
    ThumbUpAlt,
    TimeToLeave,
    Water,
    Work,
} from "@mui/icons-material";
import { TileTag } from "@tilescape/common";
import React from "react";

export const tagToIcon = (tag: TileTag, tiny: boolean) => {
    let component = <DoNotDisturb />;

    switch (tag) {
        case TileTag.Building:
            component = <Home />;
            break;
        case TileTag.Person:
            component = <Person />;
            break;
        case TileTag.Tall:
            component = <Straight />;
            break;
        case TileTag.Protective:
            component = <Shield />;
            break;
        case TileTag.Destructive:
            component = <HeartBroken />;
            break;
        case TileTag.Vehicle:
            component = <TimeToLeave />;
            break;
        case TileTag.Nature:
            component = <Spa />;
            break;
        case TileTag.Animal:
            component = <Pets />;
            break;
        case TileTag.Flying:
            component = <Flight />;
            break;
        case TileTag.Water:
            component = <Water />
            break;
        case TileTag.Mountain:
            component = <Landscape />;
            break;
        case TileTag.Forest:
            component = <Park />;
            break;
        case TileTag.Desert:
            component = <LightMode />;
            break;
        default:
            break;
    }

    return React.cloneElement(component, { style: { fontSize: tiny ? 12 : 20 } });
};