import { Box, Button, Grid, Typography } from "@mui/material";
import { useAppContext } from "src/context/AppContext";
import GameBoard from "./GameBoard";
import CasinoIcon from "@mui/icons-material/Casino";
import ReplayIcon from "@mui/icons-material/Replay";
import ScoreCard from "./ScoreCard";
import useAudio from "src/hooks/useAudio";
import { useEffect } from "react";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import BuildIcon from "@mui/icons-material/Build";
import { Room, Tile, Player, getScoreForPlayer } from "@tilescape/common";
import { useNavigate } from "react-router-dom";

type PlayerScore = {
  name: string;
  score: number;
};

type Props = {
  room: Room;
};
export default function RoomFinished({ room }: Props) {
  const context = useAppContext();
  const navigate = useNavigate();

  const handleCellClick = (_: Tile) => {};

  const [_, toggle] = useAudio("/audio/gameover.wav");

  useEffect(() => {
    toggle();
  }, []);

  const isMeHost = context.connectionId === room.hostSocketId;

  // Find winner
  const findWinner = () => {
    const scores: PlayerScore[] = room.players.map((player: Player) => {
      return {
        name: player.name,
        score: getScoreForPlayer(room.settings, room.gameState.world, player),
      };
    });
    const maxScore = Math.max(...scores.map((p) => p.score));
    return scores.filter((player) => player.score === maxScore);
  };
  const winners: PlayerScore[] = findWinner();

  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 1, textAlign: "center" }}>
        {winners.length === 1 ? "WINNER" : "TIE"}
      </Typography>
      {winners.map((winner, index) => (
        <Typography key={index} variant="h4" sx={{ textAlign: "center" }}>
          {winner.name} ({winner.score})
        </Typography>
      ))}
      <Box sx={{ mb: 2, mt: 1, display: "flex", justifyContent: "center" }}>
        <GameBoard room={room} onClick={handleCellClick} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container sx={{ maxWidth: "600px" }} spacing={1} columns={2}>
          {isMeHost ? (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => context.api.finishedRoomBackToLobby()}
              >
                <BuildIcon /> Goto Lobby
              </Button>
            </Grid>
          ) : null}
          {isMeHost ? (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => context.api.startRoomPlay()}
              >
                <ReplayIcon /> Same Tiles
              </Button>
            </Grid>
          ) : null}
          {isMeHost ? (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  context.api.randomizeRoomTiles();
                  context.api.startRoomPlay();
                }}
              >
                <CasinoIcon /> Random Tiles
              </Button>
            </Grid>
          ) : null}
          {isMeHost ? (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => context.api.newRandomGame()}
              >
                <CasinoIcon /> Random Settings
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(99,99,99,1) 0%, rgba(66,66,66,1) 100%)",
            borderRadius: 2,
            p: 1,
            textAlign: "center",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          {!isMeHost ? (
            <Typography variant="h5" sx={{ color: "white" }}>
              Wait for host to start a new game
            </Typography>
          ) : null}
          <ScoreCard room={room} players={room.players} />
          <Button
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => {
              context.api.leaveRoom();
              navigate("/rooms");
            }}
          >
            <BackIcon /> Leave Room
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
