import { ALL_TILECONTENT } from "./TileContentDef";

export function isEmpty(tileContent: TileContent): boolean {
  return ALL_TILECONTENT.EMPTY.includes(tileContent as TileContentEmpty);
}

export function isNature(tileContent: TileContent): boolean {
  return ALL_TILECONTENT.NATURE.includes(tileContent as TileContentNature);
}

export function isBuilding(tileContent: TileContent): boolean {
  return ALL_TILECONTENT.BUILDING.includes(tileContent as TileContentBuilding);
}

// Empty

type EmptyTuple = typeof ALL_TILECONTENT.EMPTY;
export type TileContentEmpty = EmptyTuple[number];

// Nature

type NatureTuple = typeof ALL_TILECONTENT.NATURE;
export type TileContentNature = NatureTuple[number];

// BUILDING

type BuildingTuple = typeof ALL_TILECONTENT.BUILDING;
export type TileContentBuilding = BuildingTuple[number];

export type TileContent = TileContentEmpty | TileContentNature | TileContentBuilding;
