import React from "react";
import { useEffect, useState } from "react";
import {
  useBeforeUnload,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Loader from "src/component/Loader";
import { useAppContext } from "src/context/AppContext";
import AreYouSureDialog from "./subcomponents/AreYouSureDialog";
import { LeaveRoomPlayingDialog } from "./subcomponents/Dialogs";
import RoomFinished from "./subcomponents/RoomFinished";
import RoomLobby from "./subcomponents/RoomLobby";
import RoomPlaying from "./subcomponents/RoomPlaying";

export default function Room() {
  const { roomId } = useParams() as { roomId: string };
  const context = useAppContext();
  const room = context.room;

  useEffect(() => {
    // The URL is a room with room ID.
    // Ask server to join the room.
    if (room === null) context.api.joinRoom(roomId);
  }, []);

  if (room === null) return <></>;

  return (
    <>
      {room === null ? <Loader /> : null}
      {room.state === "Waiting" ? <RoomLobby room={room} /> : null}
      {room.state === "Playing" ? (
        <>
          <RoomPlaying room={room} />
        </>
      ) : null}
      {room.state === "Finished" ? <RoomFinished room={room} /> : null}
    </>
  );
}
