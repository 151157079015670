export enum TileTag {
    // Base
    Nature,
    Water,
    Forest,
    Mountain,
    Desert,

    Building,
    Person,
    Vehicle,
    Animal,

    // Attributes
    Destructive,
    Protective,
    Tall,
    Flying,
}

const nature_tags = [TileTag.Nature, TileTag.Water, TileTag.Forest, TileTag.Mountain, TileTag.Desert];

export function isTileTagNature(tag: TileTag) {
    return nature_tags.includes(tag);
}