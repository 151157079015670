export const ALL_TILECONTENT = {
  EMPTY: ["Empty"],
  NATURE: ["Forest", "Mountain", "Water", "Desert", "NationalPark"],
  BUILDING: [
    "Ship",
    "Camp",
    "Church",
    "Fisherman",
    "Hospital",
    "House",
    "Market",
    "Metro",
    "Factory",
    "Fountain",
    "Miner",
    "Lumberjack",
    "AmusementPark",
    "Truck",
    "Castle",
    "Mage",
    "School",
    "Hero",
    "Crane",
    "GasStation",
    "Helicopter",
    "Bike",
    "GolfCourse",
    "Satellite",
    "Bank",
    "Chicken",
    "Wolf",
    "Police",
    "Photographer",
    "Firetruck",
    "Rocket",
    "T_Rex",
    "Painter",
    "Ninja",
    "Elf",
    "Giant",
    "Telescope",
    "RobotArm",
    "Fish",
    "RundownHouse",
    "Zombie",
    "Fairy",
    // "Wall",
    "Baby",
    // "Snake",
    // "Dragon",
    // "HorseRider",
    // "Ghost",
    // "Monkey",
    // "Parrot",
  ],
} as const;