
export let possiblePlayerColors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#9a6324",
    "#800000",
    "#134922",
    "#808080",
]


export function getRandomPossiblePlayerColor() {
    return possiblePlayerColors[Math.floor(Math.random() * possiblePlayerColors.length)]
}

export function getRandomColor(): string {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
}