import { CircularProgress, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useAppContext } from "src/context/AppContext";
import { getScoreForPlayer, Player, Room } from "@tilescape/common";
import PlayerColorCircle from "./PlayerColorCircle";

type Props = {
  room: Room;
  players: Player[];
};


export default function ScoreCard({ room, players }: Props) {
  const context = useAppContext();
  const matches500 = useMediaQuery('(min-width:500px)');

  return (
    <Grid container columns={2}>
      {players.map((player: Player, index: number) => {
        const hasTurn = room.players[room.gameState.playerTurnIndex].id === player.id;
        const isMe = player.id === context.connectionId;
        const name = player.name + (isMe ? ' (Me)' : '');

        return (
          <Grid item xs={1} key={index} sx={{ mb: .5 }}>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                height: '100%',
                padding: 0.5,
                margin: .25,
                borderRadius: 2,
                backgroundColor: hasTurn && room.state === "Playing" ? "#777777" : null,
                border: (room.state === 'Playing' && hasTurn) || room.state === 'Finished' ? null : '1px solid #777777',
                color: "white",
              }}
            >
              <Stack
                key={index}
                direction="row"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                spacing={1}
              >
                <Typography variant="body1">{index + 1}.</Typography>
                {player.connected ? (
                  <PlayerColorCircle color={player.color} />
                ) : (
                  <CircularProgress disableShrink style={{ width: 20, height: 20, color: player.color }} />
                )}
                <Typography variant="body1" sx={{ textAlign: "left", wordBreak: 'break-word' }}>
                  {!matches500 && name.length > 10 ? name.substring(0, 10) + '...' : name}
                </Typography>
              </Stack>
              <Typography sx={{ textAlign: "center" }} variant="h4">
                {getScoreForPlayer(room.settings, room.gameState.world, player)}
              </Typography>
            </Stack>
          </Grid>
        );
      })}
    </Grid >
  );
}
