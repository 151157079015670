type Props = {
    color: string
}

export default function PlayerColorCircle({ color }: Props) {
    return (
        <span
            style={{
                width: 20,
                height: 20,
                minWidth: 20,
                minHeight: 20,
                borderRadius: "50%",
                backgroundColor: color,
            }}
        />
    );
}